import "./style.scss";
import { useModalState } from "@clipboard-health/ui-react";
import { IonIcon } from "@ionic/react";
import { DialogContent } from "@mui/material";
import { ColleagueList } from "@src/app/components/workWithFriends/colleagueList";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { useAppSelector } from "@src/app/store";
import { IRecentColleagueInfo } from "@src/app/store/recentColleagues/model";
import { FullScreenDialog } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { USER_EVENTS } from "@src/constants";
import { caretForward } from "ionicons/icons";
import { FC, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { ColleaguePictureList } from "./colleaguePictureList";
import { ColleaguesSummary } from "./colleagueSummary";
import { ViewAddFriendModalContext, WorkWithFriendsProps } from "./interface";
import { UploadProfilePictureModal } from "./uploadProfilePictureModal";
import { getColleagueList, getNumberOfOpenShiftsInSlot } from "./utils";

export const WorkWithFriends: FC<WorkWithFriendsProps> = ({ shiftSlot, shift, context }) => {
  const [openProfilePicModal, setOpenProfilePicModal] = useState(false);

  const history = useHistory();
  const { isProfilePictureUploaded, hideAsColleagueOnShifts, address, userId } = useDefinedWorker();
  const recentColleagues = useAppSelector(
    (state) => state.recentColleaguesStore.workers ?? []
  ) as Array<IRecentColleagueInfo>;
  const workerFriends = useAppSelector(
    (state) => state.workerFriendsStore.workerFriends.data ?? []
  );
  const workerFriendRequests = useAppSelector(
    (state) => state.workerFriendsStore.workerFriendRequests.SENT.data
  );

  const colleagueListModalState = useModalState();

  const colleagues = useMemo(() => {
    return getColleagueList({
      hideAsColleagueOnShifts,
      workerFriends,
      recentColleagues,
      workerFriendRequests,
      shiftSlotBookedByList: shiftSlot?.bookedBy,
      isShiftCompleted: false,
      userId,
    });
  }, [
    shiftSlot.bookedBy,
    recentColleagues,
    workerFriends,
    hideAsColleagueOnShifts,
    workerFriendRequests,
    userId,
  ]);
  const areMultipleShiftsAvailable = Object.values(shiftSlot?.stats).some(
    (currentStat) => currentStat.totalShifts > currentStat.assignedShifts
  );
  if (!areMultipleShiftsAvailable) {
    /**
     * FIXME: Move this guard upstream to the callers.
     */
    return null;
  }
  const handleShowColleagueListOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    logEvent(USER_EVENTS.OPEN_SHIFT_COLLEAGUES_VIEWED, {
      start: shift?.start,
      end: shift?.end,
      facility_id: shift?.facilityId ?? shift?.facility?.userId,
      shift_id: shift?.shiftId,
      worker_msa: address?.metropolitanStatisticalArea,
      hourly_pay: shift?.pay,
      agent_req: shift?.agentReq,
      shift_type: shift?.name,
      num_open_shifts_in_slot: getNumberOfOpenShiftsInSlot(shiftSlot?.stats),
      num_colleagues_shown: shiftSlot?.bookedBy?.length,
      has_missing_documents: false,
      has_work_with_friends_feature_flag_on: true,
      has_profile_picture_uploaded: !!isProfilePictureUploaded,
      worker_is_hidden_as_colleague_on_shifts: !!hideAsColleagueOnShifts,
      num_familiar_colleagues_shown: colleagues?.filter((colleague) => colleague.shiftStart)
        ?.length,
      num_friends_shown: colleagues?.filter((colleague) => colleague.isFriend)?.length,
      context,
    });

    if (isProfilePictureUploaded) {
      colleagueListModalState.openModal();
      return;
    }

    setOpenProfilePicModal(true);
  };

  const handleOnContinueClick = () => {
    setOpenProfilePicModal(false);
    history.push(TabRouterPath.PROFILE, { isUpdateProfileImageModalOpen: true });
  };

  const shouldShowColleagueList = Boolean(colleagues?.length);

  const viewAddFriendModalContext: ViewAddFriendModalContext =
    context === "my-shifts" ? "COLLEAGUES_LIST_UPCOMING_SHIFT" : "COLLEAGUES_LIST_OPEN_SHIFT";

  return (
    <>
      <div
        className="work-with-friends"
        data-testid="work-with-friends"
        {...(shouldShowColleagueList ? { onClick: handleShowColleagueListOnClick } : {})}
      >
        {shouldShowColleagueList && isProfilePictureUploaded && (
          <div className="colleague-picture-list-container">
            <ColleaguePictureList colleagues={colleagues} />
            <div className="show-colleague-list-link">
              <span>{hideAsColleagueOnShifts ? "Show all friends" : "Show all colleagues"}</span>
              <IonIcon style={{ height: "12px" }} size="small" icon={caretForward} />
            </div>
          </div>
        )}
        <ColleaguesSummary
          colleagueList={colleagues}
          shiftSlotStats={shiftSlot.stats}
          context={context}
          shiftQualification={shift?.agentReq}
        />
      </div>
      {openProfilePicModal && (
        <UploadProfilePictureModal
          isOpen={openProfilePicModal}
          closeModal={() => setOpenProfilePicModal(false)}
          handleOnContinueClick={handleOnContinueClick}
        />
      )}

      <FullScreenDialog modalState={colleagueListModalState}>
        <DialogContent>
          <ColleagueList
            viewAddFriendModalContext={viewAddFriendModalContext}
            context={context}
            isShiftCompleted={false}
            shiftId={shift._id ?? ""}
            onBackClick={() => colleagueListModalState.closeModal()}
          />
        </DialogContent>
      </FullScreenDialog>
    </>
  );
};
